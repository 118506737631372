import React from 'react'
import MobileHomePicsSection from './MobileHomePicsSection'
import MobileNewsSection from './MobileNewsSection'
import MobileOurClientsSection from './MobileOurClientsSection'
import MobileWhyUsSection from './MobileWhyUsSection'

const MobileHomePageMain:React.FC = () => {
    return (
        <div>
            <MobileHomePicsSection/>
            <MobileWhyUsSection/>
            <MobileOurClientsSection/>
            <MobileNewsSection/>
        </div>
    )
}

export default MobileHomePageMain
