/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import SectionHeader from '../../app/layout/SectionHeader';
import figure from '../../img/Asset 1.svg';

const AdminSoftwarePageMain: React.FC = () => {
	return (
		<div>
			<SectionHeader content={'Софтуерни системи за администрацията'} />
			<div
				style={{ padding: '3rem 5rem' }}
				className='hotel-purpose-text fixed-wraper'>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Предлагаме и
					съдействаме при внедряването и експлоатацията на почти
					цялата гама от серийни продукти на колегите от{' '}
					<a
						className='softsystems-link-dark'
						href='http://www.softsystems.eu/'
						target='_blank'
						rel='noreferrer'>
						Софтсистемс
					</a>
					. Програмните пакети{' '}
					<strong>
						Счетоводство, Заплати и персонал, Фактуриране, Хонорари,
						Складово стопанство
					</strong>{' '}
					имат високо реноме и са добре познати на специализираната
					аудитория с богата функционалност, висока надеждност и
					стриктно съответствие с правната уредба.
				</p>
				<div className='admin-images-flex'>
					<a
						href='http://www.softsystems.eu/Product_Fso'
						target='_blank'
						rel='noreferrer'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure fso-img'
							/>
						</figure>
					</a>
					<a
						href='http://www.softsystems.eu/Product_Zapl'
						target='_blank'
						rel='noreferrer'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure zapl-img'
							/>
						</figure>
					</a>
					<a
						href='http://www.softsystems.eu/Product_Fakt'
						target='_blank'
						rel='noreferrer'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure fakt-img'
							/>
						</figure>
					</a>
					<a
						href='http://www.softsystems.eu/Product_Hono'
						target='_blank'
						rel='noreferrer'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure hono-img'
							/>
						</figure>
					</a>
					<a
						href='http://www.softsystems.eu/Product_Sklad'
						target='_blank'
						rel='noreferrer'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure sklad-img'
							/>
						</figure>
					</a>
				</div>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Известно е,
					от друга страна, че почти всяка обичайна икономическа
					дейност приключва на нечие счетоводно работно място. Затова
					беше логично да използваме отворените канали за прием на
					външни данни и да интегрираме до ръба на възможното
					предлаганите от нас системи със Счетоводство и Заплати.
					Смятаме, че резултатът от интеграцията представлява
					завършена софтуерна инфраструктура за предприятия от
					медицинската и хотелиерската сфери.
				</p>
			</div>
		</div>
	);
};

export default AdminSoftwarePageMain;
