/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import dkc3_logo from '../../../img/ourClinetsLogos/DKC3_logo.jpg';
import dkc5_logo from '../../../img/ourClinetsLogos/DKC5_logo.jpg';
import dkc2_logo from '../../../img/ourClinetsLogos/DKC22_logo.jpg';
import okrujna_logo from '../../../img/ourClinetsLogos/svanna_logo.jpg';
import dkcChaika_logo from '../../../img/ourClinetsLogos/DKCChaika_logo.jpg';
import dkcEkvita_logo from '../../../img/ourClinetsLogos/DKCEkwita_logo.jpg';
import medicalLife_logo from '../../../img/ourClinetsLogos/medicalLife_logo.jpg';
import OCChudotvorec_logo from '../../../img/ourClinetsLogos/NikolaiChudotworec_logo.jpg';
import novaClinic_logo from '../../../img/ourClinetsLogos/novaClinic_logo.jpg';
import stelt2013_logo from '../../../img/ourClinetsLogos/stelt2013_logo.jpg';
import tiumf_logo from '../../../img/ourClinetsLogos/triumftaxi-logo.jpg';
import hotelBalcanci_logo from '../../../img/ourClinetsLogos/Balkanci-family-BG.jpg';

const MobileOurClientsSection: React.FC = () => {
	const secondaryOptions = {
		height: '9rem',
		gap: 40,
		cover: true,
		rewind: true,
		perPage: 2,
		// breakpoints: {
		// 	599: {
		// 		fixedWidth: 66,
		// 		height: 40,
		// 	},
		// },
	};
	return (
		<div className='our-clients-section-container'>
			<div className='section-header-mobile'>Наши клиенти</div>

			<div className='splide-container-mobile'>
				<hr className='splide-top-line-mobile' />
				<Splide options={secondaryOptions}>
					<SplideSlide>
						<img src={dkc3_logo} alt='Image 1' />
					</SplideSlide>{' '}
					<SplideSlide>
						<img src={OCChudotvorec_logo} alt='Image 2' />
					</SplideSlide>
					<SplideSlide>
						<img src={stelt2013_logo} alt='Image 2' />
					</SplideSlide>
					<SplideSlide>
						<img src={okrujna_logo} alt='Image 1' />
					</SplideSlide>{' '}
					<SplideSlide>
						<img src={novaClinic_logo} alt='Image 1' />
					</SplideSlide>
					<SplideSlide>
						<img src={dkc5_logo} alt='Image 2' />
					</SplideSlide>
					<SplideSlide>
						<img src={medicalLife_logo} alt='Image 1' />
					</SplideSlide>
					<SplideSlide>
						<img src={dkcEkvita_logo} alt='Image 2' />
					</SplideSlide>
					<SplideSlide>
						<img src={dkcChaika_logo} alt='Image 1' />
					</SplideSlide>
					<SplideSlide>
						<img src={dkc2_logo} alt='Image 1' />
					</SplideSlide>
					<SplideSlide>
						<img src={tiumf_logo} alt='Image 1' />
					</SplideSlide>
					<SplideSlide>
						<img src={hotelBalcanci_logo} alt='Image 1' />
					</SplideSlide>
				</Splide>
				<hr className='splide-bottom-line-mobile' />
			</div>
		</div>
	);
};

export default MobileOurClientsSection;
