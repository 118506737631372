import React from 'react';
import SectionHeader from '../../app/layout/SectionHeader';
import OtherProjectsContent from './OtherProjectsContent';

const OtherProjectsMain:React.FC = () => {
	return (
		<div>
			<SectionHeader content={'Индивидуални проекти'} />
			<OtherProjectsContent />
		</div>
	);
};

export default OtherProjectsMain;
