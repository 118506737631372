import React from 'react';
import SectionHeader from '../../app/layout/SectionHeader';
import ContactsGrid from './ContactsGrid';
import GoogleMapComponent from './GoogleMapComponent';

const ContactsPageMain :React.FC= () => {
	return (
		<div>
            <SectionHeader content={'Контакти'}/>
			<GoogleMapComponent />
			<ContactsGrid />
		</div>
	);
};

export default ContactsPageMain;
