import React from 'react';
import { Checkbox, Input, Modal } from 'semantic-ui-react';

interface IProp {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
}

const LogInModal: React.FC<IProp> = ({ open, setOpen }) => {
	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
            closeOnDimmerClick={false}
			closeIcon={true}
			style={{
				width: '30%',
				backgroundColor: '#fff2ef',
				borderRadius: '50px',
				padding: '3rem 5rem',
			}}
			open={open}>
			<h3 className='modal-header'>ВХОД</h3>
			<div style={{margin:'2rem 0'}}>
				<div className='modal-input-label'>Потребителско име</div>
				<Input className='modal-input'></Input>
			</div>
			<div style={{margin:'2rem 0'}}>
				<div className='modal-input-label'>Парола</div>
				<Input type='password' className='modal-input'></Input>
			</div>
            <div style={{textAlign:'right'}}> <Checkbox label='Запомни ме' /></div>
            <button style={{textAlign:'center'}} className='warning-btn'>Вход</button>
		</Modal>
	);
};

export default LogInModal;
