import React from 'react'
import SectionHeader from '../../app/layout/SectionHeader'
import MedicalExtraFunctionality from './MedicalExtraFunctionality'
import MedicalFunctionality from './MedicalFunctionality'
import MedicalPageHistoryComponent from './MedicalPageHistoryComponent'
import MedicalPagePurposeComponent from './MedicalPagePurposeComponent'

const MedicalSoftwarePageMain:React.FC = () => {
    return (
        <div>
            <SectionHeader content={'Медицински софтуер'} />
            <MedicalPagePurposeComponent/>
            <MedicalPageHistoryComponent/>
            <MedicalFunctionality/>
            <MedicalExtraFunctionality/>
        </div>
    )
}

export default MedicalSoftwarePageMain
