import React from 'react';

const NewsSection:React.FC = () => {
	return (
		<div className='news_section fixed-wrapper'>
			<div className='section-header'>Последни новини</div>
			<div className='section-header'>Очаквайте скоро...</div>

			{/* <ul className='cards'>
				<li className='cards__item'>
					<div className='card'>
						
						<div className='card__content'>
							<div className='card__title'>
								Заглавие на новината
							</div>
							<div className='card__date'>31.05.2021</div>
							<p className='card__text'>
								Противно на всеобщото вярване, Lorem Ipsum не е
								просто случаен текст. Неговите корени са в
								класическата Латинска литература от 45г.пр.Хр.,
								което прави преди повече от 2000 години.
							</p>
						</div>
					</div>
				</li>
				<li className='cards__item'>
					<div className='card'>
						
						<div className='card__content'>
							<div className='card__title'>
								Още една новина, която трябва да съобщим
							</div>
							<div className='card__date'>12.05.2021</div>
							<p className='card__text'>
								Всички Lorem Ipsum генератори в Интернет
								използват предефинирани пасажи, който се
								повтарят, което прави този този генератор първия
								истински такъв.Всички Lorem Ipsum генератори в
								Интернет използват предефинирани пасажи, който
								се повтарят, което прави този този генератор
								първия истински такъв.Всички Lorem Ipsum
								генератори в Интернет използват предефинирани
								пасажи, който се повтарят, което прави този този
								генератор първия истински такъв.
							</p>
						</div>
					</div>
				</li>
				<li className='cards__item'>
					<div className='card'>
						
						<div className='card__content'>
							<div className='card__title'>Няма заглавие</div>
							<div className='card__date'>05.05.2021</div>
							<p className='card__text'>
								Ако искате да използвате пасаж от Lorem Ipsum,
								трябва да сте сигурни, че в него няма смущаващи
								или нецензурни думи.
							</p>
						</div>
					</div>
				</li>
			</ul> */}
		</div>
	);
};

export default NewsSection;
