/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import dino_panel_pic from '../../../img/dino_panel.png';

const DinoMenuItem: React.FC = () => {
	return (
		<div>
			<div className='flex-container'>
				<div className='medical-history-text'>
					<p style={{ textAlign: 'center' }}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						Поддържа два основни типа функционалност - управление на
						текущите сметки и складови операции.
					</p>
				</div>
				<img src={dino_panel_pic} className='hotel-menu-item-pic' />
			</div>
			<div className='hotel-menu0item-list-container'>
				<h3>Управление на сметките</h3>
				<p>
					Панелът за управление е проектиран за работа по{' '}
					<strong>touch screen</strong> технология, зарежда се при
					стартиране, има добра нагледност, лесна навигация и много
					ограничени възможности за операторски грешки. Функционално
					са предвидени:
				</p>
				<ul>
					<li>възможност за работа с няколко подобекта /зали/</li>
					<li>
						прилагане на предварително дефинирани схеми за отстъпки
						с различна степен на достъпност за персонала
					</li>
					<li>маси тип Бар с автоматично приключване</li>
					<li>
						възможност за добавяне на допълнителни указания към
						кухня
					</li>
					<li>
						автоматизирано разпределяне на печата на поръчките
						според конфигурацията на термопринтерите - към Бар/ове,
						Кухня/и или без отпечатване; всяко работно място може да
						обслужва толкова кухненски принтера, колкото хардуерната
						му конфигурация позволява
					</li>

					<li>
						възможност за сторниране от неприключена сметка със
						съхраняване на сторнираните позиции в регистър за
						последващ контрол
					</li>
					<li>
						автоматична оценка на различните ДДС ставки при
						закриване и печат на съответното фискално устройство
					</li>
					<li>
						възможност за сторниране от приключена сметка (не от
						сервитьор) с автоматизирано генериране на сторно касов
						бон
					</li>
					<li>
						закриване с карта чрез автоматично управляем пос
						терминал (ако има)
					</li>
					<li>
						прехвърляне на задължението към активна хотелска сметка
					</li>
					<li>автоматизирано фактуриране от сметка</li>

					<li>задължения по персонал</li>
					<li>
						сервизни операции за фискалните устройства - служебно
						въвеждане и извеждане на суми, всички видове отчети
					</li>
					<li>
						автоматизирано осчетоводяване на продажбите в софтуера
						на колегите от{' '}
						<a
							href='http://www.softsystems.eu/'
							target='_blank'
							rel='noreferrer'>
							Софтсистемс
						</a>
					</li>
				</ul>

				<h3>Складови операции</h3>
				<ul>
					<li>
						двустепенна номенклатура с продукти и изделия по
						рецепта. Допустими са произволен брой нива на влагане на
						изделие в изделие.{' '}
					</li>
					<li>
						пресмятане на актуална себестойност на база цени от
						последна доставка
					</li>
					<li>дефиниране на рецепти</li>
					<li>работа с различни ДДС ставки</li>
					<li>
						обвързване на продуктите с предварително дефинирани
						складове
					</li>
					<li>
						предварително изготвяне на ценоразпис към бъдещ момент
					</li>
					<li>
						обработка на операции по доставка на продукти чрез
						фактури и стокови разписки
					</li>
					<li>
						автоматизирано отписване на продукти, вложени в продажби
						по меню
					</li>
					<li>
						автоматизирано остойностяване на разхода по средно
						претеглена за месец цена
					</li>
					<li>
						въвеждане на първоначални салда (ако софтуерът се
						въвежда „в движение“)
					</li>
					<li>оборотна ведомост и справки от движение за продукт</li>
					<li>
						автоматизирано осчетоводяване на покупки/продажби в
						софтуера на колегите от{' '}
						<a
							href='http://www.softsystems.eu/'
							target='_blank'
							rel='noreferrer'>
							Софтсистемс
						</a>
						. Пресмятане по складове стойността на вложените
						суровини, които подлежат на счетоводно отписване.{' '}
					</li>
				</ul>
			</div>
		</div>
	);
};

export default DinoMenuItem;
