import React from 'react';
import HomePicsSection from './HomePicsSection';
import NewsSection from './NewsSection';
import OurClientsSection from './OurClientsSection';
import WhyUsSection from './WhyUsSection';

interface IProp {
	setIsSubMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const HomePageMain: React.FC<IProp> = ({
	setIsSubMenuIsVisible,
}) => {
	return (
		<div>
			<HomePicsSection
				setIsSubMenuIsVisible={setIsSubMenuIsVisible}
			/>
			<WhyUsSection />
			<OurClientsSection />
			<NewsSection />
		</div>
	);
};

export default HomePageMain;
