import React from 'react'
import MobileHotelAccordionMenu from './MobileHotelAccordionMenu'
import MobileHotelPurposeSection from './MobileHotelPurposeSection'

const MobileHotelSoftwarePageMain:React.FC = () => {
    return (
        <div>
            <MobileHotelPurposeSection/>
            <MobileHotelAccordionMenu/>
        </div>
    )
}

export default MobileHotelSoftwarePageMain
