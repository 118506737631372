/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import screen_pic from '../../../img/medical_functionality_img.png';
import checkSVG from '../../../img/Group 88.svg';

const MobileBaseFunctionalitySection: React.FC = () => {
	return (
		<div className='mobile-medical-baseFunctionality-section'>
			<h2>Базова функционалност</h2>
			<p>
				Във времето се оформиха неписани стандарти и днес повечето
				регулярно използвани медицински софтуери (
				<strong>MedEx/MediLab </strong>също) съчетават богата вътрешна
				функционалност с мащабна интеграция към множество външни
				административни и технически системи. Може дори да се твърди, че
				поради многопосочната си външна свързаност тези програми
				представляват уникална група в своята категория софтуер. Ето
				неговите типични характеристики:
			</p>
			<div className='mobile-medilab-benefits-grid-container'>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						технически улеснения при първоначалната подготовка на
						данните
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						интеграция с лабораторна апаратура и висока степен на
						автоматизиране на дейността в медико-диагностичните
						звена
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						организация и управление на пациентските потоци чрез
						координация Регистратура - Кабинет
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						частично отваряне към пациента под формата на онлайн
						достъп до лабораторни резултати
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						управление на разплащания с пациенти (в брой, карти),
						сторно операции, сервизни действия с фискалните
						устройства - приключване и отчети, служебно
						въвеждане/извеждане на суми
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						максимална интеграция с услугите на НЗОК (медицински и
						финансови данни), НОИ (болнични листове и пенсионен
						статус), НАП (здравноосигурителни данни), а от 2020-а и
						с непредвидимо развиващия се проект за НЗИС - електронни
						медицински документи
					</p>
				</div>

				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						множество инструменти за ускорено попълване и тест на
						медицинските документи
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						мерки за защита по съвременните стандарти за сигурност
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						мерки за справяне със свръхусложнената нормативна рамка
						на НЗОК
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						в повечето случаи СУПТО съответствие с широко
						обсъжданата наредба Н-18 на НАП
					</p>
				</div>
				<div className='medilab-benefits-grid-item'>
					<img src={checkSVG} className='mobile-checkSVG' />
					<p>
						поддръжка във времето на ценови списъци, искове към
						здравни застрахователи, фактуриране
					</p>
				</div>
			</div>
			<img src={screen_pic} className='medical-functionality-pic' />
		</div>
	);
};

export default MobileBaseFunctionalitySection;
