/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import LogInModal from '../../app/layout/LogInModal';
import warningSVG from '../../img/Warning_colored.svg';

const ActualizationPageWarning:React.FC = () => {
	const [open, setOpen] = useState(false);
	return (
		<div className='fixed-wraper'>
			<div className='actualization-warning-box'>
				<div className='actualization-warning-flex'>
					<img className='warning-svg' src={warningSVG} />
					<p className='warning-paragraph'>
						За достъп до обновленията на MedEx и MediLab е нужна
						регистрация. Използвайте потребителското име и паролата
						и ще станат достъпни актуализациите на онези продукти,
						които използвате. При затруднения ще помогнем чрез
						обявения в <NavLink to='/contacts' className='softsystems-link-dark'> Контакти </NavLink>електронен адрес или по телефон.
					</p>
				</div>
				<div className='warning-btn-box'>
					<button
						onClick={() => setOpen(true)}
						className='warning-btn'>
						Вход
					</button>
				</div>
			</div>
			<LogInModal open={open} setOpen={setOpen} />
		</div>
	);
};

export default ActualizationPageWarning;
