import React, { Fragment, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import AboutUsPageMain from '../../features/aboutUsPage/AboutUsPageMain';
import ActualizationPageMain from '../../features/actualizationPage/ActualizationPageMain';
import AdminSoftwarePageMain from '../../features/adminSoftwarePage/AdminSoftwarePageMain';
import ContactsPageMain from '../../features/contactsPage/ContactsPageMain';
import HomePageMain from '../../features/homepage/HomePageMain';
import HotelSoftwarePageMain from '../../features/hotelSoftwarePage/HotelSoftwarePageMain';
import MedicalSoftwarePageMain from '../../features/mediLabPage/MedicalSoftwarePageMain';
import MobileHomePageMain from '../../features/mobile/homepage/MobileHomePageMain';
import MobileHotelSoftwarePageMain from '../../features/mobile/hotelSoftwarePage/MobileHotelSoftwarePageMain';
import MobileMedicalSoftwarePageMain from '../../features/mobile/medicalSoftwarePage/MobileMedicalSoftwarePageMain';
import NewsPageMain from '../../features/newsPage/NewsPageMain';
import OtherProjectsMain from '../../features/otherProjectsPage/OtherProjectsMain';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import MobileNavBar from './MobileNavBar';
import NavBar from './NavBar';

const App: React.FC = () => {
	const [isMobile, setIsMobile] = useState(false);
	const [isSubMenuIsVisible, setIsSubMenuIsVisible] = useState(false);

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 830);
	};

	useEffect(() => {
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
	}, []);

	return (
		<div className='App'>
			{!isMobile ? (
				<Fragment>
					<Route path={'/'}>
						<NavBar
							setIsSubMenuIsVisible={setIsSubMenuIsVisible}
							isSubMenuIsVisible={isSubMenuIsVisible}
						/>
					</Route>
					<Route exact path={'/'}>
						<HomePageMain
							setIsSubMenuIsVisible={setIsSubMenuIsVisible}
						/>
					</Route>
					<Route
						exact
						path={'/medicalSoftware'}
						component={MedicalSoftwarePageMain}
					/>
					<Route
						exact
						path={'/hotelSoftware'}
						component={HotelSoftwarePageMain}
					/>
					<Route
						exact
						path={'/otherProjects'}
						component={OtherProjectsMain}
					/>
					<Route
						exact
						path={'/adminSoftware'}
						component={AdminSoftwarePageMain}
					/>

					<Route
						exact
						path={'/contacts'}
						component={ContactsPageMain}
					/>
					<Route
						exact
						path={'/actualizations'}
						component={ActualizationPageMain}
					/>
					<Route
						exact
						path={'/aboutUs'}
						component={AboutUsPageMain}
					/>
					<Route exact path={'/news'} component={NewsPageMain} />
					<Route path={'/'}>
						<Footer setIsSubMenuIsVisible={setIsSubMenuIsVisible} />
					</Route>
				</Fragment>
			) : (
				<Fragment>
					<Route path={'/'} component={MobileNavBar} />
					<Route exact path={'/'} component={MobileHomePageMain} />
					<Route
						exact
						path={'/medicalSoftware'}
						component={MobileMedicalSoftwarePageMain}
					/>
					<Route
						exact
						path={'/hotelSoftware'}
						component={MobileHotelSoftwarePageMain}
					/>
					<Route
						exact
						path={'/otherProjects'}
						component={OtherProjectsMain}
					/>
					<Route
						exact
						path={'/adminSoftware'}
						component={AdminSoftwarePageMain}
					/>
					<Route
						exact
						path={'/actualizations'}
						component={ActualizationPageMain}
					/>
					<Route exact path={'/news'} component={NewsPageMain} />
					<Route
						exact
						path={'/aboutUs'}
						component={AboutUsPageMain}
					/>
					<Route
						exact
						path={'/contacts'}
						component={ContactsPageMain}
					/>
					<Route path={'/'} component={MobileFooter} />
				</Fragment>
			)}
		</div>
	);
};

export default App;
