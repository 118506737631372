/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../img/Logo-white.png';
import LogInModal from './LogInModal';
interface IProp {
	setIsSubMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const Footer: React.FC<IProp> = ({ setIsSubMenuIsVisible }) => {
	const onPageChange = () => {
		setIsSubMenuIsVisible(false);
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const [open, setOpen] = useState(false);
	return (
		<footer className='footer'>
			<div className='fixed-wraper'>
				<div className='footer-flex-container'>
					<div className='footer-flex-first-item'>
						<img className='footer-logo' src={logo} />
						{/* <p className='footer-paragraph'>
							
						</p> */}
						{/* <button
							className='footer-btn'
							onClick={() => setOpen(true)}>
							Вход
						</button> */}
					</div>

					<div className='footer-flex-second-item-flex-container'>
						<div
							className='footer-flex-second-item-flex-menu'
							style={{ paddingRight: '3rem' }}>
							<div className='footer-flex-second-item-flex-header'>
								Връзки
							</div>
							<ul className='footer-flex-second-item-flex-item'>
								<li>
									{' '}
									<NavLink
										to='/aboutUs'
										onClick={onPageChange}>
										За нас
									</NavLink>{' '}
								</li>
								<li>
									{' '}
									<NavLink
										to='/contacts'
										onClick={onPageChange}>
										Контакти
									</NavLink>
								</li>
								{/* <li>
									{' '}
									<NavLink
										to='/actualizations'
										onClick={onPageChange}>
										Актуализации
									</NavLink>
								</li> */}
								<li>
									{' '}
									<NavLink to='/news' onClick={onPageChange}>
										Новини
									</NavLink>
								</li>
							</ul>
						</div>
						<div className='footer-flex-second-item-flex-menu'>
							<div className='footer-flex-second-item-flex-header'>
								Услуги
							</div>
							<ul className='footer-flex-second-item-flex-item'>
								<li>
									<NavLink
										to='/medicalSoftware'
										onClick={onPageChange}>
										Медицински софтуер
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/hotelSoftware'
										onClick={onPageChange}>
										Хотелски софтуер
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/otherProjects'
										onClick={onPageChange}>
										Индивидуални проекти
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/adminSoftware'
										onClick={onPageChange}>
										Системи за администрацията
									</NavLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='footer-ending-text'>
				© {new Date().getFullYear()} DATA STUDIO - Всички права
				запазени!
			</div>
			<LogInModal open={open} setOpen={setOpen} />
		</footer>
	);
};

export default Footer;
