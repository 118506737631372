import React from 'react';

const ContactsGrid: React.FC = () => {
	return (
		<div className='contacts-grid-container fixed-wraper'>
			<div>
				<div className='contacts-header-flex'>
					<span className='material-icons contacts-header-icon'>
						location_on
					</span>{' '}
					<div className='contacts-header-text'>Адрес:</div>
				</div>
				<div className='contacts-text'>
					гр.Варна, ул. Христо Попович 35
				</div>{' '}
			</div>
			<div>
				<div className='contacts-header-flex'>
					<span className='material-icons contacts-header-icon'>
						phone_iphone
					</span>
					<div className='contacts-header-text'>Телефон:</div>
				</div>

				<a className='contacts-text softsystems-link-dark' href='tel:+35952650013'>
					+ 359 52 650 013
				</a>
			</div>
			<div>
				<div className='contacts-header-flex'>
					<span className='material-icons contacts-header-icon'>
						email
					</span>{' '}
					<div className='contacts-header-text'>E-mail:</div>
				</div>
				<a
					href='mailto:datastudio@b-trust.org'
					className='contacts-text softsystems-link-dark'>
					datastudio@b-trust.org
				</a>{' '}
			</div>
		</div>
	);
};

export default ContactsGrid;
