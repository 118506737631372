/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import dino_mobile_pic from '../../../img/dino_mobile_screen.png';

const DinoMobileMenuItem: React.FC = () => {
	return (
		<div>
			<div className='flex-container'>
				<div className='medical-history-text hotel-menu0item-list-container'>
					<ul>
						<li>
							инсталира се върху мобилно устройство (телефон,
							таблет) под Android версия 8 и по-висока.{' '}
						</li>
						<li>
							елиминира нуждата от междинно записване на
							поръчката на хартия, ограничава случаите на погрешно
							регистриране.{' '}
						</li>
						<li>
							поддържа почти цялата функционалност, достъпна
							през настолна работна станция - вземане на поръчка
							чрез удобен интерфейс, бележки за кухня, отстъпки,
							преглед на състоянието по отворени сметки, достъпни
							за текущия потребител, дистанционно управление на
							печата. Не е допустимо сторниране от записана
							поръчка и приключване на сметка.
						</li>
					</ul>
				</div>
				<img
					src={dino_mobile_pic}
					className='hotel-menu-item-pic'
				/>
			</div>
		</div>
	);
};

export default DinoMobileMenuItem;
