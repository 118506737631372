import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import DinoMenuItem from './hotelMenuItems/DinoMenuItem';
import DinoMobileMenuItem from './hotelMenuItems/DinoMobileMenuItem';
import HotelMenuItem from './hotelMenuItems/HotelMenuItem';

const HotelMenu: React.FC = () => {
	const [activeItem, setActiveItem] = useState('hotel');
	const handleItemClick = (e: any, name: any) => {
		setActiveItem(name.name);
	};
	return (
		<div style={{paddingTop:'5rem'}}>
			<Menu
				attached='top'
				tabular
				position='center'
				className='hotel-menu'>
				<Menu.Item
					name='hotel'
					active={activeItem === 'hotel'}
					onClick={(e, name) => handleItemClick(e, name)}>
					Хотел
				</Menu.Item>
				<Menu.Item
					name='dino'
					active={activeItem === 'dino'}
					onClick={(e, name) => handleItemClick(e, name)}>
					Ресторант /Dino/
				</Menu.Item>
				<Menu.Item
					name='dinoMobile'
					active={activeItem === 'dinoMobile'}
					onClick={(e, name) => handleItemClick(e, name)}>
					DinoMobile
				</Menu.Item>
			</Menu>

			<Segment className='hotel-menu-content-container' attached='bottom'>
				<div className='fixed-wraper'>
					{activeItem === 'hotel' ? (
						<HotelMenuItem/>
					) : activeItem === 'dino' ? (
						<DinoMenuItem/>
					) : (
						<DinoMobileMenuItem/>
					)}
				</div>
			</Segment>
		</div>
	);
};

export default HotelMenu;
