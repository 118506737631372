/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import hotel_purpose_pic from '../../../img/hotel_big_pic.png';

const MobileHotelPurposeSection: React.FC = () => {
	return (
		<div className='mobile-hotel-purpose-section'>
			<h2>Предназначение</h2>
			<p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Гостът на
				хотела очаква желанията му да се сбъдват по-бързо и е склонен да
				взема импровизирани решения по-често, отколкото в обичайния си
				бит. Това понякога поставя хотелския софтуер пред изпитания -
				резервацията, пребиваването, плащанията, фактурирането може да се
				случат по начини, които въображението на авторите не е
				предвидило. По тези причини:
				<ul>
					<li>
						постарахме се да отворим нашата хотелска платформа към
						произволни сценарии (подчинени все пак на здрав разум)
					</li>
					<li>
						положихме усилия да развържем ръцете на рецепциониста -
						всяка софтуерна работа може да се свърши тогава, когато
						не го чакат гости. Това особено помага, ако рецепцията
						не е единственото му задължение.
					</li>
					<li>
						отворихме електронен канал за обмен на документи с
						клиента - можем да работим без хартия
					</li>
					<li>
						и съчетахме това с инструменти, които пречат изненадите
						да причинят счетоводен хаос
					</li>
				</ul>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Вероятно ни
				липсват някои специфични функции, присъщи за много големи
				обекти, но сме убедени, че съчетанието, което изградихме, ще
				покрие нуждите и на много придирчив хотелски екип. Прегледайте
				подробностите и преценете ...
			</p>
			<img src={hotel_purpose_pic} className='hotel-purpose-pic' />
            <h2>Организация на софтуера: </h2>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Състои
						се от два базови модула - Хотел и Ресторант (вторият
						наречен още <strong>Dino</strong>).{' '}
						<strong>Dino</strong> има и разширение под формата на
						мобилно приложение <strong>DinoMobile</strong> (на
						телефон, таблет) за вземане на поръчки от клиенти в
						ресторант. Двата базови модула работят съвместно и имат
						функционални връзки - прехвърляне на сметки от ресторант
						към сметки за настаняване и сборни отчети за паричните
						потоци. Могат да бъдат и напълно самостоятелни.
					</p>
		</div>
	);
};

export default MobileHotelPurposeSection;
