import React from 'react'
import SectionHeader from '../../app/layout/SectionHeader'
import HotelMenu from './HotelMenu'
import HotelPagePurposeComponent from './HotelPagePurposeComponent'

const HotelSoftwarePageMain:React.FC = () => {
    return (
        <div>
             <SectionHeader content={'Хотелски софтуер'} />
             <HotelPagePurposeComponent/>
             <HotelMenu/>
        </div>
    )
}

export default HotelSoftwarePageMain
