import React from 'react';
import SectionHeader from '../../app/layout/SectionHeader';
import ActualizationPageWarning from './ActualizationPageWarning';
// import { FullFileBrowser } from 'chonky';
//import MainStore from '../../app/stores/mainStore';
import { observer } from 'mobx-react-lite';

const ActualizationPageMain: React.FC = () => {
	// const mainStore = useContext(MainStore);
	// const { files, getFiles } = mainStore;

	// useEffect(() => {
	//     if(files === undefined) {
	//         getFiles();
	//     }
	// }, [])
	return (
		// <div>
		// 	<SectionHeader content={'Актуализации'} />
		// 	{files !== undefined ? (
		// 		<div style={{ height: 300 }}>
		// 			<FullFileBrowser files={files!} />
		// 		</div>
		// 	) : (
		// 		<ActualizationPageWarning />
		// 	)}
		// </div>
		<div>
			<SectionHeader content={'Актуализации'} />
			<ActualizationPageWarning />
		</div>
	);
};

export default observer(ActualizationPageMain);
