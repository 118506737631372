import React, { useState } from 'react';
import {
	GoogleMap,
	InfoWindow,
	LoadScript,
	Marker,
} from '@react-google-maps/api';

const GoogleMapComponent: React.FC = () => {
	const [selected, setSelected] = useState(true);

	const onSelect = (item: any) => {
		setSelected(item);
	};

	const mapStyles = {
		height: '60vh',
		width: '100%',
	};

	const location = {
		name: 'Дейта Студио ООД',
		location: {
			lat: 43.209314,
			lng: 27.906852,
		},
	};

	const defaultCenter = {
		lat: 43.209314,
		lng: 27.906852,
	};

	return (
		<div>
		<LoadScript googleMapsApiKey='AIzaSyADRVI0p6ErgdQgey7xhH0gOHBN8lnvS1o'>
			<GoogleMap
				mapContainerStyle={mapStyles}
				zoom={19}
				center={defaultCenter}>
				<Marker
					key={location.name}
					position={location.location}
					onClick={() => onSelect(true)}
					clickable={true}
				/>
				{selected ? (
					<InfoWindow
						position={{
							lat: 43.209314444,
							lng: 27.906852444,
						}}
						onCloseClick={() => onSelect(false)}>
						<div>
							{' '}
							<h2>Дейта Студио</h2> <p>гр.Варна, ул. Христо Попович 35</p>{' '}
							<p>9000 Варна Център, Варна</p>
							<a href='https://www.google.com/maps/place/%D0%94%D0%B5%D0%B9%D1%82%D0%B0+%D0%A1%D1%82%D1%83%D0%B4%D0%B8%D0%BE/@43.2093165,27.9062775,19z/data=!3m1!4b1!4m5!3m4!1s0x40a45475e3cf4a99:0xb19151b1a7d5cf30!8m2!3d43.2093262!4d27.906854'>
								Преглед в Google Карти
							</a>
						</div>
					</InfoWindow>
				) : null}
			</GoogleMap>
		</LoadScript></div>
	);
};

export default GoogleMapComponent;
//AIzaSyADRVI0p6ErgdQgey7xhH0gOHBN8lnvS1o
