import React from 'react';
import SectionHeader from '../../app/layout/SectionHeader';

const NewsPageMain: React.FC = () => {
	return (
		<div>
			<SectionHeader content={'Новини'} />
			<div style={{ fontSize: '5rem', textAlign: 'center' }}>
				Очаквайте скоро...
			</div>
		</div>
	);
};

export default NewsPageMain;
