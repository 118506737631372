import React from 'react';
interface IProp {
	content: string;
}
const SectionHeader: React.FC<IProp> = ({ content }) => {
	return (
		<div className='section-title'>
			<div className=' home-pics-section-background  fixed-wraper'>
				{' '}
				<div className='section-title-text'>{content}</div>{' '}
			</div>
		</div>
	);
};

export default SectionHeader;
