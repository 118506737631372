/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import figure from '../../../img/Asset 1.svg';

const MobileWhyUsSection: React.FC = () => {
	return (
		<div>
			<div className='section-header-mobile'>Защо нас?</div>
			<div className='why-us-section-grid-mobile'>
				<div className='why-us-section-grid-item-mobile '>
					<figure>
						<img
							className='why-us-section-figure-mobile software-figure' 
							src={figure}
						/>
					</figure>
				</div>
				<div className='why-us-section-grid-item-mobile'>
					<p className='why-us-section-text-mobile'>
						Работим в нашата област отдавна и имаме точно
						формулирана професионална цел:{' '}
						<strong>
							съчетаване на добре интегриран софтуер, технически
							средства и хора, които взаимодействат надеждно и
							ефективно без външна намеса
						</strong>
						. Опитваме да постигнем това чрез лично участие и чрез
						съвети.
					</p>
				</div>
				<div className='why-us-section-grid-item-mobile'>
					<figure>
						<img
							src={figure}
							className='why-us-section-figure-mobile tehnology-figure'
						/>
					</figure>
				</div>
				<div className='why-us-section-grid-item-mobile'>
					<p className='why-us-section-text-mobile'>
						Професионалните ни пристрастия са върху софтуера, но
						можем да поемем проекта и реализацията на всички
						елементи от информационната среда. Считаме, че{' '}
						<strong>
							цялостната услуга е от полза за нашите клиенти
						</strong>{' '}
						и улеснение за самите нас.
					</p>
				</div>

				<div className='why-us-section-grid-item-mobile'>
					<figure>
						<img
							src={figure}
							className='why-us-section-figure-mobile people-figure'
						/>
					</figure>
				</div>
				<div className='why-us-section-grid-item-mobile'>
					<p className='why-us-section-text-mobile'>
						Традиционните ни теми са
						<strong>
							{' '}
							здравеопазването, хотелиерството и транспорта
						</strong>
						, но имаме опит в разучаване на процеси, както и
						капацитет за разработване на софтуерни решения в други
						области на икономиката. Възможно е някъде там да се
						срещнат нашите познания и вашите интереси.
					</p>
				</div>
			</div>
		</div>
	);
};

export default MobileWhyUsSection;
