/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import medPic from '../../img/Untitled-1.png';
import medSVG from '../../img/stethoscope.svg';
import hotelPic from '../../img/Untitled-2.png';
import hotelSVG from '../../img/calendar.svg';
import otherPic from '../../img/Untitled-3.png';
import otherSVG from '../../img/web-development-svgrepo-com.svg';
import { NavLink } from 'react-router-dom';

interface IProp {
	setIsSubMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const HomePicsSection: React.FC<IProp> = ({ setIsSubMenuIsVisible }) => {
	const onPageChange = () => {
		setIsSubMenuIsVisible(false);
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};
	return (
		<div className='home-pics-section'>
			<div className='home-pics-section-background fixed-wraper'>
				<div className='container '>
					<div className='home-pic-card'>
						<img src={hotelPic} />
						<div className='home-pic-card-text'>
							<img src={hotelSVG} className='home-pics-svg' />
							<h2>Хотелски софтуер</h2>
							<NavLink to='/hotelSoftware' onClick={onPageChange}>
								<button> Научи повече</button>
							</NavLink>
						</div>
					</div>
					<div className='home-pic-card'>
						<img src={medPic} />
						<div className='home-pic-card-text'>
							<img className='home-pics-svg' src={medSVG} />
							<h2>Медицински софтуер</h2>
							<NavLink
								to='/medicalSoftware'
								onClick={onPageChange}>
								<button> Научи повече</button>
							</NavLink>
						</div>
					</div>
					<div className='home-pic-card'>
						<img src={otherPic} />
						<div className='home-pic-card-text'>
							<img src={otherSVG} className='home-pics-svg' />
							<h2>Индивидуални проекти</h2>
							<NavLink to='/otherProjects' onClick={onPageChange}>
								<button> Научи повече</button>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePicsSection;
