/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import laptop_pic from '../../img/medical_extras_img.png';
import figure from '../../img/Asset 2.svg';

const MedicalExtraFunctionality: React.FC = () => {
	return (
		<div className='medical-extra-functionality-container '>
			<div className='fixed-wraper'>
				<div className='flex-container'>
					<div className='medical-extra-functionality-text'>
						<h2>
							... и някои рядко срещани на други места възможности
							на <strong>MedEx/MediLab</strong>
						</h2>
					</div>
					<img
						src={laptop_pic}
						className='medical-extra-functionality-pic'
					/>
				</div>
				<div className='med-extra-func-container'>
					<div className='med-extra-func-container-flex'>
						<div className='med-extra-func-container-figure'>
							<figure className='aboutUs-grid-item-2'>
								<img
									src={figure}
									className='why-us-section-figure web-portal-figure'
								/>
							</figure>
						</div>
						<div>
							<h3 className='med-extra-func-container-header'>
								Интегриран в системата пациентски Web портал.
							</h3>
							<p>
								Той включва <span style={{textDecoration:'underline'}}>напълно автоматизирано и окончателно
								резервиране на часове</span> за посещение. Убедени сме,
								че потребителите в медицински център, които
								<ul>
									<li>
										ежедневно разговарят с пациенти, за да
										съгласуват часове
									</li>
									<li>
										и/или преписват данни на пациенти от
										разпространените национални Web системи
										за резервиране във вътрешната система на
										Центъра
									</li>
								</ul>{' '}
								ще оценят тази възможност.
							</p>
							<p>
								По преценка на управляващия екип Порталът може
								да осигури на пациента и достъп до пълната
								документация за неговите посещения. Отчитаме, че
								все по-често типичните потребители на здравни
								услуги произхождат от възрастова група с
								изградени навици за използване на уеб
								инструменти.
							</p>
						</div>
					</div>
					<div className='med-extra-func-container-flex'>
						<div>
							<h3 className='med-extra-func-container-header'>
								Интеграция с използваната в медицинския център
								система за дигитализиране на резултати от
								образни изследвания (PACS).
							</h3>
							<p>
								Съхранените в цифрова форма образи са директно
								достъпни за лекуващия лекар през личния
								електронен картон на пациента.
							</p>
						</div>
						<div className='med-extra-func-container-figure'>
							<figure className='aboutUs-grid-item-2'>
								<img
									src={figure}
									className='why-us-section-figure rengen-figure'
								/>
							</figure>
						</div>
					</div>
					<div className='med-extra-func-container-flex'>
						<div className='med-extra-func-container-figure'>
							<figure className='aboutUs-grid-item-2'>
								<img
									src={figure}
									className='why-us-section-figure sofsystems-figure'
								/>
							</figure>
						</div>
						<div>
							<h3 className='med-extra-func-container-header'>
								Интеграция с административния софтуер на
								колегите от{' '}
								<a
									href='http://www.softsystems.eu/'
									target='_blank'
									rel='noreferrer'>
									Софтсистемс
								</a>
								.
							</h3>
							<ul>
								<li>
									автоматизирано съставяне на счетоводни
									записвания за приходите в съответствие с
									приетата счетоводна политика и изискванията
									за аналитичност по лекари, специалности
									и/или други признаци
								</li>
								<li>
									генериране и трансфер към софтуер “Заплати”
									на начисления за формиране на
									възнагражденията на персонала според
									критериите за разпределение на приходите
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MedicalExtraFunctionality;
