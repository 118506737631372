/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import figure from '../../img/Asset 1.svg';

const AboutUsPageContent: React.FC = () => {
	return (
		<div className='fixed-wraper'>
			<div className='aboutUs-section-box'>
				<p className='aboutUs-grid-item-1'>
					Работим под името <strong>„Дейта Студио”</strong> от 2012-а.
					Към онзи момент вече имахме 15 години опит, ясни правила и
					подход, който стриктно следваме.
				</p>
				<figure className='aboutUs-grid-item-2'>
					<img src={figure} className='why-us-section-figure logo-small-img' />
				</figure>
				<p className='aboutUs-grid-item-3'>
					Сега, както и в началото, смятаме че добре подредени и
					правилно употребени електронни данни вдигат потенциала на
					всяка рутинна дейност. Днес не е нужно това да се доказва,
					така че усилията ни са върху същинската задача -
					сглобяването на ефективни и надеждни информационни системи.
				</p>
				<p className='aboutUs-grid-item-4'>
					Правим това като отделяме нужното внимание на всеки детайл,
					защото в нашата област всеки пропуск може драматично
					да влоши нещата.
					<p>
						{' '}
						Правим го чрез усилията на екип, който балансирано
						съчетава опит и познаване на съвременните базови
						технологии за софтуерна разработка.
					</p>
				</p>
				<figure className='aboutUs-grid-item-5'>
					<img src={figure} className='why-us-section-figure team_img' />
				</figure>
			</div>
		</div>
	);
};

export default AboutUsPageContent;
