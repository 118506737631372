import React from 'react';
import SectionHeader from '../../../app/layout/SectionHeader';
import MobileBaseFunctionalitySection from './MobileBaseFunctionalitySection';
import MobileExtraFunctionalitySection from './MobileExtraFunctionalitySection';
import MobileHistorySection from './MobileHistorySection';
import MobilePurposeSection from './MobilePurposeSection';

const MobileMedicalSoftwarePageMain: React.FC = () => {
	return (
		<div>
			<SectionHeader content={'Медицински софтуер'} />
            <MobilePurposeSection/>
			<MobileHistorySection/>
			<MobileBaseFunctionalitySection/>
			<MobileExtraFunctionalitySection/>
		</div>
	);
};

export default MobileMedicalSoftwarePageMain;
