/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import hotel_reservation_img from '../../../img/hotel_reservation_panel.png';

const HotelMenuItem: React.FC = () => {
	return (
		<div>
			<div className='flex-container'>
				<div className='medical-history-text'>
					<p style={{ textAlign: 'center' }}>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						Работата с <strong>Хотел</strong> е организирана в
						четири базови документа - Резервация, Сметка, Фактура,
						Свободна продажба, интегрирани в удобен и нагледен панел
						с бърз достъп до практически всички функционалности на
						модула.
					</p>
				</div>
				<img
					src={hotel_reservation_img}
					className='hotel-menu-item-pic'
				/>
			</div>
			<div className='hotel-menu0item-list-container'>
				<h3>Резервация, която наред с обичайното позволява:</h3>
				<ul>
					<li>
						начисляване на депозит според пакета и като процент от
						бъдещата сметка
					</li>
					<li>
						автоматизирано съставяне и изпращане на e-mail до
						клиента с данните по резервацията, банкови детайли и
						указания за оформяне на плащането
					</li>
					<li>
						обработка на извлечения от банка в XML формат с
						автоматизирано разпознаване на плащанията и закриване на
						задълженията по депозити
					</li>
					<li>
						фактуриране на платени депозити и автоматизирано
						изпращане по e-mail на фактурата до клиента
					</li>
					<li>
						проследяване на неплатени депозити, платени
						нефактурирани, неусвоени аванси, признаване на
						нереализирани резервации, възстановяване на неусвоени
						аванси
					</li>
					<li>
						генериране на сметка/и от резервация по различни
						сценарии
					</li>
				</ul>
				<h3>Сметка, в която са предвидени:</h3>
				<ul>
					<li>начисляване на допълнителни услуги</li>
					<li>
						разплащания по различни технически способи - в брой/с
						карта/усвояване на депозит от външна резервационна
						система чрез пос терминал или различни комбинации от
						горните
					</li>
					<li>
						разплащания по различни времеви сценарии - еднократни в
						произволен момент, наколко частични, допълнителни след
						добавяне на допълнителни услуги, извършени преди и след
						издаване на фактура
					</li>
					<li>
						автоматизирано управление на периферия, свързана с
						разплащания - фискални устройства на{' '}
						<strong>Datecs</strong> (с поддръжка на техните сервизни
						функции) и пос терминал за картови операции
					</li>
					<li>
						фактуриране по различни сценарии - само за текуща
						сметка, събиране на няколко сметки, няколко фактури от
						една сметка; автоматично отделяне на туристическа такса
						и операции по различните ДДС ставки; автоматизирано
						изпращане на фактури към мейл адрес на клиента
					</li>
					<li>
						механизъм за приспадане на депозит по различни сценарии
					</li>

					<li>
						тест за консистентност на сметката - чрез съпоставка на
						начислени услуги, приспадания на депозити, плащания и
						фактуриране
					</li>
					<li>генериране на трансфер към система ЕСТИ</li>
					<li>
						специализирани отчети с оперативна и счетоводна
						насоченост
					</li>
				</ul>
				<h3>
					Фактура (или Дебитно/Кредитно известие) и Свободна продажба
				</h3>
				<ul>
					<li>
						Фактурата (Известието) се генерира в типичния случай
						автоматично от Резервация и Сметка, но е възможна и
						ръчна операция
					</li>
					<li>
						Свободната продажба се регистрира чрез основния панел за
						управление и обичайно обслужва продажби към клиенти,
						които не са настанени
					</li>
					<li>
						За автоматизираното осчетоводяване на двата вида
						документи е изработена интеграция със счетоводния
						софтуер на колегите от{' '}
						<a
							href='http://www.softsystems.eu/'
							target='_blank'
							rel='noreferrer'>
							Софтсистемс
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default HotelMenuItem;
