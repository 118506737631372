import React from 'react';
import medical_purpose_pic from '../../../img/medical_purpose.png';

const MobilePurposeSection: React.FC = () => {
	return (
		<div className='mobile-medical-purpose-section'>
			<h2>Предназначение</h2>
			<p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<strong>MedEx</strong> (за амбулаторна дейност) и{' '}
				<strong>MediLab</strong> (за МДЛаборатория) представляват
				цялостна софтуерна среда за управление на всички аспекти на
				дейността в{' '}
				<strong>специализираното извънболнично здравеопазване</strong> -
				групови практики на медицински специалисти, медицински и
				диагностично - консултативни центрове, лаборатории като част от
				тях, както и самостоятелни диагностични звена.
			</p>
            <img src={medical_purpose_pic} className='medical-purpose-pic' />
		</div>
	);
};

export default MobilePurposeSection;
