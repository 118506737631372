/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import figure from '../../img/Asset 1.svg';

const WhyUsSection: React.FC = () => {
	return (
		<div className='fixed-wraper'>
			<div className='section-header'>Защо нас?</div>
			<div className='whyUsSection-background-img'>
				<div className='why-us-section-grid'>
					<div className='why-us-section-grid-item why-us-section-grid-item-1'>
						<figure>
							<img
								className='why-us-section-figure tehnology-figure'
								src={figure}
							/>
						</figure>
					</div>
					<div className='why-us-section-grid-item why-us-section-grid-item-2'>
						<p className='why-us-section-text'>
							Работим в нашата област отдавна и имаме точно
							формулирана професионална цел:{' '}
							<strong>
								съчетаване на добре интегриран софтуер,
								технически средства и хора, които взаимодействат
								надеждно и ефективно без външна намеса
							</strong>
							. Опитваме да постигнем това чрез лично участие и
							чрез съвети.
						</p>
					</div>
					<div className='why-us-section-grid-item why-us-section-grid-item-3'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure people-figure'
							/>
						</figure>
					</div>
					<div className='why-us-section-grid-item why-us-section-grid-item-4'>
						<p className='why-us-section-text'>
							Професионалните ни пристрастия са върху софтуера, но
							можем да работим върху проекта и реализацията на всички
							елементи от информационната среда. Считаме, че{' '}
							<strong>
								цялостната услуга е от полза за нашите клиенти
							</strong>{' '}
							и улеснение за самите нас.
						</p>
					</div>

					<div className='why-us-section-grid-item why-us-section-grid-item-5'>
						<figure>
							<img
								src={figure}
								className='why-us-section-figure software-figure'
							/>
						</figure>
					</div>
					<div className='why-us-section-grid-item why-us-section-grid-item-6'>
						<p className='why-us-section-text'>
							Традиционните ни теми са
							<strong>
								{' '}
								здравеопазването, хотелиерството и транспорта
							</strong>
							, но имаме опит в разучаването на процеси и
							капацитет за разработване на софтуерни решения в
							други области на икономиката. Възможно е някъде там
							да се срещнат нашите познания и вашите интереси.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyUsSection;
