/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import medSVG from '../../../img/stethoscope.svg';
import hotelSVG from '../../../img/calendar.svg';
import otherSVG from '../../../img/web-development-svgrepo-com.svg';
import { NavLink } from 'react-router-dom';

const MobileHomePicsSection:React.FC = () => {
	return (
		<div className='home-pics-section-mobile'>
			<div className='home-pics-section-background-mobile'>
			<div className='mobile-home-pic-1'>
				<div className='home-text-mobile'>
					<img className='home-pics-svg-mobile' src={medSVG} />
					<div>
						<h2>Медицински софтуер</h2>
                        <NavLink to='/medicalSoftware'><button>Научи повече</button></NavLink>
						
					</div>
				</div>
			</div>
			<div className='mobile-home-pic-2'>
				<div className='home-text-mobile left-placed'>
					<img src={hotelSVG} className='home-pics-svg-mobile' />
					<div>
						<h2>Хотелски софтуер</h2>
						<NavLink to='/hotelSoftware'>
							<button> Научи повече</button>
						</NavLink>
					</div>
				</div>
			</div>
			<div className='mobile-home-pic-3'>
				<div className='home-text-mobile'>
					<img src={otherSVG} className='home-pics-svg-mobile' />
					<div>
						<h2>Индивидуални проекти</h2>
                        <NavLink to='/otherProjects'><button>Научи повече</button></NavLink>
						
					</div>
				</div>
			</div>
		</div></div>
	);
};

export default MobileHomePicsSection;
