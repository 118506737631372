/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import figure from '../../img/Asset 1.svg';

const OtherProjectsContent: React.FC = () => {
	return (
		<div className='fixed-wraper other-projects-content'>
			<p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Работили сме
				във времето по реализация на чужди идеи/проекти и продължаваме
				да го правим. Поддържаме ниво, което позволява да присъстваме
				във всички фази - от изграждане модела на бизнес процесите до
				конструиране на хардуерната среда, която ще подслони софтуера.
			</p>
			<p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Смятаме за наше
				предимство няколко важни обстоятелства:
			</p>
			<div className='other-projects-grid'>
				<figure className='otherProjects-grid-item-1'>
					<img
						src={figure}
						className='otherProjects-figure other-projects-pic-1'
					/>
				</figure>
				<p className='otherProjects-grid-item-2'>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; слушаме
					много внимателно и задаваме много въпроси - за да разбираме
					нещата не по-зле от тези, които ги вършат и за да
					предотвратим големи изненади
				</p>
				<p className='otherProjects-grid-item-3'>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; използваме
					и комбинираме всички базови технологии за разработка - за да
					получи всеки потребител онзи софтуерен инструмент, който приляга
					най-добре на мястото и задачата
				</p>
				<figure className='otherProjects-grid-item-4'>
					<img
						src={figure}
						className='otherProjects-figure  other-projects-pic-2'
					/>
				</figure>
				<figure className='otherProjects-grid-item-5'>
					<img
						src={figure}
						className='otherProjects-figure other-projects-pic-3'
					/>
				</figure>
				<p className='otherProjects-grid-item-6'>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					интересуваме се от самостоятелния професионален живот на
					нашите приложения - знаем, че с общи усилия те могат да
					станат още по-добри
				</p>
			</div>
			<p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ако прецените,
				че можем да сме полезни, споделете вашата идея ...
			</p>
		</div>
	);
};

export default OtherProjectsContent;
