/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import hotel_reservation_img from '../../../img/hotel_reservation_panel.png';
import dino_panel_pic from '../../../img/dino_panel.png';
import dino_mobile_pic from '../../../img/dino_mobile_screen.png';

const MobileHotelAccordionMenu: React.FC = () => {
	const [activeIndex, setActiveIndex] = useState(-1);

	const handleClick = (e: any, props: any) => {
		let newIndex = activeIndex === props.index ? -1 : props.index;
		setActiveIndex(newIndex);
	};

	return (
		<Accordion styled>
			<Accordion.Title
				active={activeIndex === 0}
				index={0}
				onClick={(e, props) => handleClick(e, props)}>
				<Icon name='dropdown' />
				Хотел
			</Accordion.Title>
			<Accordion.Content
				active={activeIndex === 0}
				className='accordion-content'>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Работата с{' '}
					<strong>Хотел</strong> е организирана в четири базови
					документа - Резервация, Сметка, Фактура, Свободна продажба,
					интегрирани в удобен и нагледен панел с бърз достъп до
					практически всички функционалности на модула.
				</p>
				<h3>Резервация, която наред с обичайното позволява:</h3>
				<p>
					<ul>
						<li>
							начисляване на депозит според пакета и като процент
							от бъдещата сметка
						</li>
						<li>
							автоматизирано съставяне и изпращане на e-mail до
							клиента с данните по резервацията, банкови детайли и
							указания за оформяне на плащането
						</li>
						<li>
							обработка на извлечения от банка в XML формат с
							автоматизирано разпознаване на плащанията и
							закриване на задълженията по депозити
						</li>
						<li>
							фактуриране на платени депозити и автоматизирано
							изпращане по e-mail на фактурата до клиента
						</li>
						<li>
							проследяване на неплатени депозити, платени
							нефактурирани, неусвоени аванси, признаване на
							нереализирани резервации, възстановяване на
							неусвоени аванси
						</li>
						<li>
							генериране на сметка/и от резервация по различни
							сценарии
						</li>
					</ul>
					<h3>Сметка, в която са предвидени:</h3>
					<ul>
						<li>начисляване на допълнителни услуги</li>
						<li>
							разплащания по различни технически способи - в
							брой/с карта/усвояване на депозит от външна
							резервационна система чрез пос терминал или различни
							комбинации от горните
						</li>
						<li>
							разплащания по различни времеви сценарии -
							еднократни в произволен момент, наколко частични,
							допълнителни след добавяне на допълнителни услуги,
							извършени преди и след издаване на фактура
						</li>
						<li>
							автоматизирано управление на периферия, свързана с
							разплащания - фискални устройства на{' '}
							<strong>Datecs</strong> (с поддръжка на техните
							сервизни функции) и пос терминал за картови операции
						</li>
						<li>
							фактуриране по различни сценарии - само за текуща
							сметка, събиране на няколко сметки, няколко фактури
							от една сметка; автоматично отделяне на туристическа
							такса и операции по различните ДДС ставки;
							автоматизирано изпращане на фактури към мейл адрес
							на клиента
						</li>
						<li>
							механизъм за приспадане на депозит по различни
							сценарии
						</li>

						<li>
							тест за консистентност на сметката - чрез съпоставка
							на начислени услуги, приспадания на депозити,
							плащания и фактуриране
						</li>
						<li>генериране на трансфер към система ЕСТИ</li>
						<li>
							специализирани отчети с оперативна и счетоводна
							насоченост
						</li>
					</ul>
					<h3>
						Фактура (или Дебитно/Кредитно известие) и Свободна
						продажба
					</h3>
					<ul>
						<li>
							Фактурата (Известието) се генерира в типичния случай
							автоматично от Резервация и Сметка, но е възможна и
							ръчна операция
						</li>
						<li>
							Свободната продажба се регистрира чрез основния
							панел за управление и обичайно обслужва продажби към
							клиенти, които не са настанени
						</li>
						<li>
							За автоматизираното осчетоводяване на двата вида
							документи е изработена интеграция със счетоводния
							софтуер на колегите от{' '}
							<a
								href='http://www.softsystems.eu/'
								target='_blank'
								rel='noreferrer'>
								Софтсистемс
							</a>
						</li>
					</ul>
				</p>
				<img
					src={hotel_reservation_img}
					className='hotel-menu-item-pic'
				/>
			</Accordion.Content>

			<Accordion.Title
				active={activeIndex === 1}
				index={1}
				onClick={(e, props) => handleClick(e, props)}>
				<Icon name='dropdown' />
				Ресторант /Dino/
			</Accordion.Title>
			<Accordion.Content
				active={activeIndex === 1}
				className='accordion-content'>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Поддържа
					два основни типа функционалност - управление на текущите
					сметки и складови операции.
				</p>
				<p>
					<h3>Управление на сметките</h3>
					<p>
						Панелът за управление е проектиран за работа по{' '}
						<strong>touch screen</strong> технология, зарежда се при
						стартиране, има добра нагледност, лесна навигация и
						много ограничени възможности за операторски грешки.
						Функционално са предвидени:
					</p>
					<ul>
						<li>възможност за работа с няколко подобекта /зали/</li>
						<li>
							прилагане на предварително дефинирани схеми за
							отстъпки с различна степен на достъпност за
							персонала
						</li>
						<li>маси тип Бар с автоматично приключване</li>
						<li>
							възможност за добавяне на допълнителни указания към
							кухня
						</li>
						<li>
							автоматизирано разпределяне на печата на поръчките
							според конфигурацията на термопринтерите - към
							Бар/ове, Кухня/и или без отпечатване; всяко работно
							място може да обслужва толкова кухненски принтера,
							колкото хардуерната му конфигурация позволява
						</li>

						<li>
							възможност за сторниране от неприключена сметка със
							съхраняване на сторнираните позиции в регистър за
							последващ контрол
						</li>
						<li>
							автоматична оценка на различните ДДС ставки при
							закриване и печат на съответното фискално устройство
						</li>
						<li>
							възможност за сторниране от приключена сметка (не от
							сервитьор) с автоматизирано генериране на сторно
							касов бон
						</li>
						<li>
							закриване с карта чрез автоматично управляем пос
							терминал (ако има)
						</li>
						<li>
							прехвърляне на задължението към активна хотелска
							сметка
						</li>
						<li>автоматизирано фактуриране от сметка</li>

						<li>задължения по персонал</li>
						<li>
							сервизни операции за фискалните устройства -
							служебно въвеждане и извеждане на суми, всички
							видове отчети
						</li>
						<li>
							автоматизирано осчетоводяване на продажбите в
							софтуера на колегите от{' '}
							<a
								href='http://www.softsystems.eu/'
								target='_blank'
								rel='noreferrer'>
								Софтсистемс
							</a>
						</li>
					</ul>

					<h3>Складови операции</h3>
					<ul>
						<li>
							двустепенна номенклатура с продукти и изделия по
							рецепта. Допустими са произволен брой нива на
							влагане на изделие в изделие.{' '}
						</li>
						<li>
							пресмятане на актуална себестойност на база цени от
							последна доставка
						</li>
						<li>дефиниране на рецепти</li>
						<li>работа с различни ДДС ставки</li>
						<li>
							обвързване на продуктите с предварително дефинирани
							складове
						</li>
						<li>
							предварително изготвяне на ценоразпис към бъдещ
							момент
						</li>
						<li>
							обработка на операции по доставка на продукти чрез
							фактури и стокови разписки
						</li>
						<li>
							автоматизирано отписване на продукти, вложени в
							продажби по меню
						</li>
						<li>
							автоматизирано остойностяване на разхода по средно
							претеглена за месец цена
						</li>
						<li>
							въвеждане на първоначални салда (ако софтуерът се
							въвежда „в движение“)
						</li>
						<li>
							оборотна ведомост и справки от движение за продукт
						</li>
						<li>
							автоматизирано осчетоводяване на покупки/продажби в
							софтуера на колегите от{' '}
							<a
								href='http://www.softsystems.eu/'
								target='_blank'
								rel='noreferrer'>
								Софтсистемс
							</a>
							. Пресмятане по складове стойността на вложените
							суровини, които подлежат на счетоводно отписване.{' '}
						</li>
					</ul>
				</p>
				<img src={dino_panel_pic} className='hotel-menu-item-pic' />
			</Accordion.Content>

			<Accordion.Title
				active={activeIndex === 2}
				index={2}
				onClick={(e, props) => handleClick(e, props)}>
				<Icon name='dropdown' />
				DinoMobile
			</Accordion.Title>
			<Accordion.Content
				active={activeIndex === 2}
				className='accordion-content'>
				<p>
					<ul>
						<li>
							инсталира се върху мобилно устройство (телефон,
							таблет) под Android версия 8 и по-висока.{' '}
						</li>
						<li>
							елиминира нуждата от междинно записване на поръчката
							на хартия, ограничава случаите на погрешно
							регистриране.{' '}
						</li>
						<li>
							поддържа почти цялата функционалност, достъпна през
							настолна работна станция - вземане на поръчка чрез
							удобен интерфейс, бележки за кухня, отстъпки,
							преглед на състоянието по отворени сметки, достъпни
							за текущия потребител, дистанционно управление на
							печата. Не е допустимо сторниране от записана
							поръчка и приключване на сметка.
						</li>
					</ul>
				</p>
				<img src={dino_mobile_pic} className='hotel-menu-item-pic' />
			</Accordion.Content>
		</Accordion>
	);
};

export default MobileHotelAccordionMenu;
