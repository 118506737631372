/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Sidebar } from 'semantic-ui-react';
import logo from '../../img/Logo-white.png';

const MobileNavBar: React.FC = () => {
	const [visible, setVisible] = useState(false);

	const onPageChange = () => {
		setVisible(false);
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	return (
		<nav className='navigation-mobile'>
			<div className='navBar-container-mobile'>
				<Link to='/'>
					<img src={logo} className='navigation-logo-mobile' />
				</Link>
				<div className='navBar_menu'>
					<span
						onClick={() => setVisible(true)}
						className='material-icons sidebar-menu-icon'>
						menu
					</span>
					<Sidebar
						as={Menu}
						animation='overlay'
						direction='right'
						inverted
						onHide={() => setVisible(false)}
						vertical
						visible={visible}
						width='wide'>
						<div className='close-sidebar-div'>
							<span
								onClick={() => setVisible(false)}
								className='material-icons sidebar-menu-icon'>
								clear
							</span>
						</div>
						<Menu.Item as='a'>
							<NavLink
								exact
								to='/'
								activeClassName='active-route'
								onClick={onPageChange}>
								Начало
							</NavLink>
						</Menu.Item>
						<Menu.Item as='a'>
							Софтуер
							<Menu.Menu>
								<Menu.Item as='a'></Menu.Item>
								<Menu.Item as='a'>
									<NavLink
										to='/medicalSoftware'
										activeClassName='active-route'
										onClick={onPageChange}>
										Медицински софтуер
									</NavLink>
								</Menu.Item>
								<Menu.Item as='a'>
									<NavLink
										to='/hotelSoftware'
										activeClassName='active-route'
										onClick={onPageChange}>
										Хотелски софтуер
									</NavLink>
								</Menu.Item>
								<Menu.Item as='a'>
									<NavLink
										to='/otherProjects'
										activeClassName='active-route'
										onClick={onPageChange}>
										Индивидуални проекти
									</NavLink>
								</Menu.Item>
								<Menu.Item as='a'>
									<NavLink
										to='/adminSoftware'
										activeClassName='active-route'
										onClick={onPageChange}>
										Системи за администрацията
									</NavLink>
								</Menu.Item>
							</Menu.Menu>
						</Menu.Item>
						{/* <Menu.Item as='a'>
							<NavLink
								to='/actualizations'
								activeClassName='active-route'
								onClick={onPageChange}>
								Актуализации
							</NavLink>
						</Menu.Item> */}
						<Menu.Item as='a'>
							<NavLink
								to='/news'
								activeClassName='active-route'
								onClick={onPageChange}>
								Новини
							</NavLink>
						</Menu.Item>
						<Menu.Item as='a'>
							<NavLink
								to='/aboutUs'
								activeClassName='active-route'
								onClick={onPageChange}>
								За нас
							</NavLink>
						</Menu.Item>
						<Menu.Item as='a'>
							<NavLink
								to='/contacts'
								activeClassName='active-route'
								onClick={onPageChange}>
								Контакти
							</NavLink>
						</Menu.Item>
					</Sidebar>
				</div>
			</div>
		</nav>
	);
};

export default MobileNavBar;
