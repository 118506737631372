/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../img/Logo-white.png';

interface IProp {
	setIsSubMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isSubMenuIsVisible: boolean;
}
const NavBar: React.FC<IProp> = ({
	setIsSubMenuIsVisible,
	isSubMenuIsVisible,
}) => {
	const onPageChange = () => {
		setIsSubMenuIsVisible(false);
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	return (
		<nav className='navigation'>
			<div className='navBar-container fixed-wraper'>
				<Link to='/' onClick={onPageChange}>
					<img src={logo} className='navigation-logo' />
				</Link>
				<div className='navBar_menu'>
					<ul className='navBar_menu_list' role='navigation'>
						<li className='navBar_menu_item'>
							<NavLink
								exact
								to='/'
								activeClassName='active-route'
								onClick={onPageChange}>
								Начало
							</NavLink>
						</li>
						<li
							className='navBar_menu_item expand-item'
							onClick={() =>
								setIsSubMenuIsVisible(!isSubMenuIsVisible)
							}>
							<a>
								Софтуер
								{/* <i
									className='material-icons'
									style={{ verticalAlign: 'sub' }}>
									expand_more
								</i> */}
							</a>

							{/* <ul aria-label='submenu'>
								<li>
									<a>
										Медицински софтуер
										<i
											className='material-icons'
											style={{
												verticalAlign: 'sub',
											}}>
											chevron_right
										</i>
									</a>
									<ul>
										<li>
											<NavLink
												to='/MedEx'
												activeClassName='active-route'
												onClick={onPageChange}>
												Медицинска система MedEx
											</NavLink>
										</li>
										<li>
											<NavLink
												to='/medicalSoftware'
												activeClassName='active-route'
												onClick={onPageChange}>
												Лабораторна система MediLab
											</NavLink>
										</li>
									</ul>
								</li>
								<li>
									<NavLink
										to='/hotelSoftware'
										activeClassName='active-route'
										onClick={onPageChange}>
										Хотелски софтуер
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/otherProjects'
										activeClassName='active-route'
										onClick={onPageChange}>
										Поръчков софтуер
									</NavLink>
								</li>
							</ul> */}
						</li>
						{/* <li className='navBar_menu_item'>
							<NavLink
								to='/actualizations'
								activeClassName='active-route'
								onClick={onPageChange}>
								Актуализации
							</NavLink>
						</li> */}
						<li className='navBar_menu_item'>
							<NavLink
								to='/news'
								activeClassName='active-route'
								onClick={onPageChange}>
								Новини
							</NavLink>
						</li>
						<li className='navBar_menu_item'>
							<NavLink
								to='/aboutUs'
								activeClassName='active-route'
								onClick={onPageChange}>
								За нас
							</NavLink>
						</li>
						<li className='navBar_menu_item'>
							<NavLink
								to='/contacts'
								activeClassName='active-route'
								onClick={onPageChange}>
								Контакти
							</NavLink>
						</li>
					</ul>
				</div>
			</div>

			<div
				className='second-nav-expanded'
				style={{
					opacity: isSubMenuIsVisible ? '1' : '0',
					display: isSubMenuIsVisible ? 'block' : 'none',
				}}>
				<div className=' second-nav-expanded-background'>
					<ul className='fixed-wraper '>
						<li>
							<NavLink
								to='/medicalSoftware'
								activeClassName='active-route'
								onClick={onPageChange}>
								&#10004; Медицински софтуер
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/hotelSoftware'
								activeClassName='active-route'
								onClick={onPageChange}>
								&#10004; Хотелски софтуер
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/otherProjects'
								activeClassName='active-route'
								onClick={onPageChange}>
								&#10004; Индивидуални проекти
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/adminSoftware'
								activeClassName='active-route'
								onClick={onPageChange}>
								&#10004; Системи за администрацията
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
