/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../img/Logo-white.png';

const MobileFooter: React.FC = () => {
	const onPageChange = () => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};
	return (
		<footer className='footer-mobile'>
			<div style={{ textAlignLast: 'center', padding: '3rem 0rem' }}>
				<img className='footer-logo-mobile' src={logo} />
			</div>
			<div className='footer-flex-second-item-flex-container-mobile'>
				<div className='footer-flex-second-item-flex-menu-mobile'>
					<div className='footer-flex-second-item-flex-header-mobile'>
						Връзки
					</div>
					<ul className='footer-flex-second-item-flex-item-mobile'>
						<li>
							{' '}
							<NavLink to='/aboutUs' onClick={onPageChange}>
								За нас
							</NavLink>{' '}
						</li>
						<li>
							{' '}
							<NavLink to='/contacts' onClick={onPageChange}>
								Контакти
							</NavLink>
						</li>
						{/* <li>
							{' '}
							<NavLink
								to='/actualizations'
								onClick={onPageChange}>
								Актуализации
							</NavLink>
						</li> */}
						<li>
							{' '}
							<NavLink to='/news' onClick={onPageChange}>
								Новини
							</NavLink>
						</li>
					</ul>
				</div>
				<div className='footer-flex-second-item-flex-menu'>
					<div className='footer-flex-second-item-flex-header-mobile'>
						Услуги
					</div>
					<ul className='footer-flex-second-item-flex-item-mobile'>
						<li>
							<NavLink
								to='/medicalSoftware'
								onClick={onPageChange}>
								Медицински софтуер
							</NavLink>
						</li>
						<li>
							<NavLink to='/hotelSoftware' onClick={onPageChange}>
								Хотелски софтуер
							</NavLink>
						</li>
						<li>
							<NavLink to='/otherProjects' onClick={onPageChange}>
								Индивидуални проекти
							</NavLink>
						</li>
						<li>
							<NavLink to='/adminSoftware' onClick={onPageChange}>
								Системи за администрацията
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
			<div className='footer-ending-text-mobile'>
				© 2021 DATA STUDIO - Всички права запазени!
			</div>
		</footer>
	);
};

export default MobileFooter;
