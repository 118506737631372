/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import medical_history_pic from '../../img/history_screen.png';

const MedicalPageHistoryComponent: React.FC = () => {
	return (
		<div className='medical-history-container '>
			<div className='fixed-wraper'>
				<div className='flex-container'>
					<div className='medical-history-text'>
						<h2>Малко история</h2>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							Основните идеи, заложени в софтуера, са
							разработени и експериментирани в реална среда години
							преди старта на публичната здравноосигурителна
							система. Потребителите на първите версии заработиха
							с тях от есента на 1998-a. По това време в България
							практически нямаше продукти с такова предназначение.
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							През изминалите от тогава години системата постоянно
							се адаптира към променливия модел на предлагане и
							финансиране на специализирани здравни услуги. По
							тази причина може да се твърди, че днешните{' '}
							<strong>MedEx</strong> и <strong>MediLab</strong> са
							резултат на натрупан значителен опит и дори
							традиция.
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							Програмите успешно преживяха множество
							административни експерименти и днес са технологично
							готови да направят своите потребители пълноценни
							участници в добилото почти митичен образ
							е-здравеопазване.
						</p>
					</div>
					<img
						src={medical_history_pic}
						className='medical-history-pic'
					/>
				</div>
			</div>
		</div>
	);
};

export default MedicalPageHistoryComponent;
