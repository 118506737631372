import React from 'react'
import SectionHeader from '../../app/layout/SectionHeader'
import AboutUsPageContent from './AboutUsPageContent'

const AboutUsPageMain :React.FC= () => {
    return (
        <div>
            	<SectionHeader content={'За нас'} />
                <AboutUsPageContent/>
        </div>
    )
}

export default AboutUsPageMain
