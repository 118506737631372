import React from 'react'

const MobileNewsSection:React.FC = () => {
    return (
        <div>
            <div className='section-header-mobile'>Последни новини</div>
			<div className='section-header-mobile'>Очаквайте скоро...</div>
        </div>
    )
}

export default MobileNewsSection
